.dashboard-admin {
    padding: 20px;
    background-color: #f4f5f7;
}

.dashboard-card {
    border: 1px solid #007bff;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-bottom: 20px;
    text-align: center;
}

.dashboard-card:hover {
    transform: translateY(-5px);
}

.dashboard-card .card-title {
    font-size: 1.5rem;
    color: #007bff;
    text-align: center;
}

.dashboard-card .card-text {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 10px;
}

.chart-section {
    margin-top: 40px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.dashboard-col {
    flex: 1 1 30%; /* Adjust the width as necessary */
    margin: 10px;
}
