/* src/Dashboard.css */
.avatar {
    width: 56px;
    height: 56px;
  }
  
  .card {
    margin-top: 20px;
  }
  
  .button {
    margin-bottom: 10px;
  }
  