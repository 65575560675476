.scroll-container {
    overflow-x: auto;
    white-space: nowrap;
    flex-grow: 1;
    padding-bottom: 10px;
}

.scrollable-container {
    overflow-x: auto;
    white-space: nowrap;
    display: block;
    padding-bottom: 10px;
}

.scrollable-table {
    width: max-content; /* Ensures the table grows based on content */
    border-collapse: collapse;
    min-width: 100%; /* Set to 100% for the horizontal scroll */
}

.scrollable-table th, .scrollable-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.scrollable-table th {
    background-color: #f2f2f2;
}

.scrollable-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.scrollable-table tbody tr:hover {
    background-color: #f1f1f1;
}

.scrollable-container::-webkit-scrollbar {
    height: 8px;
}

.scrollable-container::-webkit-scrollbar-thumb {
    background-color: #ced4da;
    border-radius: 4px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
    background-color: #adb5bd;
}
