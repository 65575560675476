/* General styles */
.hamburger-menu {
  display: block; /* Display by default (on mobile) */
  cursor: pointer;
  position: fixed;
  top: 60px;
  left: 10px;
  z-index: 1000;
  background: #e8e4e4;
  padding: 10px;
  border-radius: 5px;
}

.hamburger-menu.open {
  left: 200px;
  transition: left 0.3s ease;
}

.menu {
  position: fixed;
  top: 0;
  left: -200px;
  width: 200px;
  height: 100%;
  background-color: #ffffff;
  transition: left 0.3s ease;
  overflow-y: auto;
  z-index: 999;
  padding-top: 60px;
}

.menu.open {
  left: 0;
}

.menu-list {
  list-style-type: none;
  padding: 0;
}

.menu-list li {
  margin: 10px 0;
}

.menu-list li a {
  color: #030303;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.menu-list li a:hover {
  background-color: #b7dbff;
}

.menu-list .dropdown {
  position: relative;
}

.menu-list .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1001; /* Ensures dropdown stays above other elements */
}

.menu-list .dropdown-menu li {
  padding: 8px 16px;
}

.menu-list .dropdown-menu li a {
  display: block;
  color: black;
  text-decoration: none;
}

.menu-list .dropdown-menu li a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown when it is toggled open */
.menu-list .dropdown-menu {
  display: block;
}

.button.is-white {
  background-color: transparent;
  border: none;
  color: #ecf0f1;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 15px;
  text-align: left;
  width: 100%;
}

.button.is-white:hover {
  background-color: #c0dfff;
}

.menu-label {
  color: #348ec9;
  padding: 15px;
  text-transform: uppercase;
  font-size: 12px;
}

/* Hide hamburger menu on desktop screens (min-width: 769px) */
@media (min-width: 769px) {
  .hamburger-menu {
    display: none;
  }

  .menu {
    left: 0; /* Ensure the sidebar is visible by default on desktop */
    width: 220px;
  }

  /* Adjust the layout to accommodate the visible sidebar */
  .content {
    margin-left: 250px; /* Ensure the main content shifts to the right of the sidebar */
  }
}

/* For mobile screens (max-width: 768px), sidebar is hidden by default */
@media (max-width: 768px) {
  .hamburger-menu {
    display: block; /* Hamburger menu visible on mobile */
  }

  .menu {
    left: -200px; /* Sidebar hidden by default on mobile */
  }

  .menu.open {
    left: 0; /* Sidebar slides in when open */
  }

  .content {
    margin-left: 0; /* Ensure content takes full width when sidebar is hidden */
  }
}
