.form-control-custom {
    border-radius: 0.5rem;
    border: 1px solid #ccc;
  }
  
  .form-card {
    border-radius: 1rem;
    padding: 2rem;
  }
  
  .custom-btn {
    background-color: #007bff;
    border: none;
    padding: 0.75rem;
    font-size: 1.1rem;
    border-radius: 0.5rem;
  }
  
  @media (min-width: 768px) {
    .form-control-custom {
      height: 45px;
    }
  }
  /* Border pada Card dan Shadow */
.form-card {
    border: 3px solid #007bff; /* Warna biru seperti pada gambar */
    padding: 30px;
    border-radius: 15px;
  }
  
  /* Judul Form */
  .form-title {
    font-size: 28px;
    font-weight: bold;
    color: #007bff;
  }
  
  /* Custom Style untuk Form Control */
  .form-control-custom {
    border: 2px solid #ced4da;
    padding: 10px;
    border-radius: 8px;
    font-size: 16px;
  }
  
  /* Tombol Custom */
  .custom-btn {
    background-color: #007bff;
    border: none;
    font-size: 18px;
    padding: 10px;
    font-weight: bold;
    border-radius: 8px;
  }
  
  /* Tambahkan Margin untuk Form Group */
  .form-group {
    margin-bottom: 20px;
  }
  
  /* Jarak antar Field yang lebih besar */
  .mb-4 {
    margin-bottom: 30px !important;
  }
  